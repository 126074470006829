export default {
  and: 'et',
  january: 'Janvier',
  february: 'Février',
  march: 'Mars',
  april: 'Avril',
  may: 'Mai',
  june: 'Juin',
  july: 'Juillet',
  august: 'Août',
  september: 'Septembre',
  october: 'Octobre',
  november: 'Novembre',
  december: 'Décembre',
  credentials_expired: 'Vos clés Twitter ont expiré. Veuillez vous reconnecter.',
  account_unverifable: "Votre compte Twitter ne peut être vérifié. La suppression de tweets peut être impossible.",
  error: 'Erreur',
  user: 'Utilisateur•ice',
  all: "Tout",
  archive: 'Archive',
  cancel: 'Annuler',
  continue: "Continuer",
  not_found: "Aucun résultat",
  search_no_results: "Votre recherche n'a donné aucun résultat",
  aborted_login: 'Connexion annulée',
  aborted_login_more: "Vous avez annulé la connexion depuis Twitter. Si vous n'êtes pas connecté, vous ne pouvez pas accéder à Archive Explorer.",
  missing_token: 'Clés d\'accès manquantes',
  missing_token_more: "Pour s'authentifier, Twitter demande certaines clés d'accès. Celles-ci sont manquantes.",
  invalid_request: 'Requête invalide',
  invalid_request_more: "La requête est invalide, ou les clés d'accès ont expiré. Essayez de vous reconnecter.",
  check_account_wait: 'Vérification de votre compte Twitter...',
  login_error_title: "Erreur de connexion",
  login_error_text: "Vos clés d'accès semblent invalides.\nEssayez de votre reconnecter.",
  login_error_persists: "Si le problème persiste, réessayez ultérieurement.",
  logout: 'Déconnexion',
  server_unavailable: "Serveur indisponible",
  server_unavailable_text: `Archive Explorer ne peut pas dialoguer avec son côté serveur.
  Vous êtes peut-être hors ligne, ou le serveur est temporairement indisponible.`,
  try_again_later: "Réessayez ultérieurement",
  reload: "Recharger",
  login_in: "Se connecter",
  archive_not_loaded: "Aucune archive chargée",
  load_valid_in: "Veuillez charger une archive valide sur la",
  load_valid_gdpr_in: "Veuillez charger une archive RGPD valide sur la",
  archive_page: "page Archive",
  gdpr_required: "Archive RGPD requise",
  how_to_download_gdpr: "Comment télécharger une archive RGPD",
  login_on: "Se connecter sur",
  dl_gdpr_step_2: `Cliquez sur les trois points, "Plus", juste au dessus du bouton de tweet`,
  dl_gdpr_step_3: `Sélectionnez "Paramètres et confidentialité"`,
  dl_gdpr_step_4: `Rendez-vous à la fin de la page, et cliquez sur "Voir mes données Twitter"`,
  dl_gdpr_step_5: `Entrez votre mot de passe, puis cliquez sur "Confirmer"`,
  dl_gdpr_step_6: `Vous pouvez maintenant cliquer sur le bouton "Demander mes données"`,
  dl_gdpr_step_end: `Le lien arrivera dans votre boîte mail.
  Ceci peut prendre un peu de temps (entre une heure et quelques jours) à se générer.
  Merci d'être patient.`,
  download_your_archive: "Téléchargez votre archive",
  close: 'Fermer',
  page_not_found: 'Page non trouvée',
  you_tried_to_show: "Vous avez essayé d'afficher",
  but_it_does_not_exists: "mais cette page n'existe pas",
  link_to: "Lien vers",
  picture: "image",
  show_your_tweets: "Afficher mes tweets",
  show_retweets: "Afficher les retweets",
  show_replies: "Afficher les réponses",
  show_all_tweets: "Afficher tous les tweets",
  show_with_medias: "Afficher les tweets avec médias",
  show_with_videos: "Afficher les tweets avec vidéos ou GIFs",
  sort_by_date: "Trier par date",
  sort_by_popular: "Trier par popularité",
  sort_by_rt_count: "Trier par nombre de retweets",
  sort_by_fav_count: "Trier par nombre de favoris",
  sort_asc: "Tri croissant",
  sort_desc: "Tri décroissant",
  contains_any_tweets: "Cet élément ne contient aucun tweet",
  filters_that_hide: `Il semblerait que vous avez appliqué des filtres
  qui cachent tous les tweets pouvant être affichés.`,
  no_tweets_to_display: "Aucun tweet à afficher ici",
  deleted_or_no_permission_to_show: "Tous les tweets sont supprimés, ou vous n'avez pas la permission de les voir.",
  try_disable_download: `Essayez de désactiver l'option "Télécharger les tweets" dans les paramètres.`,
  try_another_login_info: "Vous pouvez aussi essayer de vous connecter avec un autre compte Twitter.",
  settings: 'Paramètres',
  select_all: 'Tout sélectionner',
  unselect_all: 'Tout désélectionner',
  select_tweets_choices: 'Sélection...',
  revert_selection: "Inverser la sélection",
  tweet_selection: 'Sélection de tweets',
  selected_without_s: 'sélectionné',
  selected: 'sélectionnés',
  yes: 'Oui',
  no: 'Non',
  task_start_error: "Impossible de lancer la tâche. Vérifiez votre connexion réseau.",
  delete_selected_tweets: "Supprimer les tweets sélectionnés",
  tweets_deleted_from: "Ces tweets vont être supprimés depuis",
  deletion_modal_text: `Une tâche de suppression va être lancée.
  Vous ne pouvez pas restaurer les tweets après qu'ils soient supprimés de Twitter.
  Voulez vous vraiment faire cela ?`,
  browser_is_obsolete: 'Navigateur obsolète',
  incompatible_navigator: 'Votre navigateur web est incompatible avec Archive Explorer.',
  compatible_no_grid_1: 'Le site web peut ne pas s\'afficher correctement. Merci de mettre à jour votre navigateur afin que le site puisse fonctionner normalement.',
  compatible_no_grid_2: 'Rendez-vous dans les paramètres de votre navigateur, et recherchez les mises à jour.',
  old_web_browser: 'Vous utilisez un vieux navigateur web.',
  old_web_browser_p1: 'Ce site peut ne pas s\'afficher correctement. Une mise à jour du navigateur vous permettra une meilleur expérience de navigation.',
  old_web_browser_p2: 'En général, le processus de mise à jour est situé dans les paramètres du navigateur, ou se réalise via une mise à jour système.',
  old_microsoft_edge: 'Vous utilisez une ancienne version de Microsoft Edge.',
  old_microsoft_edge_p1: 'Merci d\'envisager la mise à jour vers la ',
  old_microsoft_edge_plink: 'nouvelle version de Microsoft Edge, basée sur Chromium',
  old_microsoft_edge_p2: 'ou changez de navigateur pour afficher ce site correctement',

  /** BEGIN PRESENTATION PAGE */
  catch_phrase: "Explorez à travers le temps, naviguez dans vos anciens messages privés et supprimez rapidement des vieux tweets embarrassants.",
  login: 'Connexion',
  explore_archive: "Explorez votre archive",
  whats_a_archive: "Qu'est-ce qu'une archive Twitter",
  whats_a_archive_p1: `Une archive est une compilation de toutes vos actions sur le réseau social. Elle contient tous vos tweets,
  messages privés, moments, favoris, blocks et bien plus.`,
  how_to_download: "Apprenez à télécharger votre archive",
  blazing_fast_loading: "Chargement instantané",
  blazing_fast_loading_p1: `Retrouvez le contenu de votre archive Twitter en quelques secondes dans Archive Explorer, sans
  aucun envoi ni téléchargement.`,
  blazing_fast_loading_p2: `La sécurité de vos données est garantie : votre fichier est décompressé, lu et exploré directement
  depuis votre navigateur, à la vitesse de l'éclair, même avec de très grandes archives. 😎`,
  powerful_search: "Recherche puissante",
  powerful_search_p1: `Archive Explorer vous permet de trouver en quelques secondes vos tweets et messages privés.
  Avec un simple champ de texte, cherchez par mot, phrase et même avec des expressions régulières.
  Tout ce que vous cherchez est à votre portée.`,
  clean_your_account: "Nettoyez votre compte",
  clean_your_account_p1: `Le vous de 13 ans a posté des tweets gênants dont vous ne vous souvenez même pas ?
  Vous avez utilisé une block-list bien trop grande pour vous ?
  Archive Explorer est un outil conçu pour supprimer vos vieux tweets, par mois, année ou même via une recherche,
  bien au-delà de la limite de 3200 tweets habituelle.
  Cet outil a déjà permis la suppression de`,
  tweets: "tweets",
  sort_and_filter_tweets: "Triez et filtrez vos tweets",
  specific_wishes: "Désirs spécifiques ? Filtres spécifiques.",
  explore_sort_p1: `Vous souhaitez explorer vos vidéos partagées ?
  Voir vos tweets les plus populaires de tous les temps ?
  Naviguez dans votre contenu sans voir aucun retweet ?`,
  explore_sort_p2: `Explorez plus précisément votre archive via un filtre de contenu :
  Trouvez vos retweets, selfies et top tweets favoris datant de plusieurs années !`,
  even_more: 'Et encore plus',
  travel_through_time: "Voyagez à travers le temps",
  travel_through_time_p1: `Explorez vos tweets ou conversations privées directement par mois ou année,
  revivez chaque moment passé sur Twitter, sans aucune barrière temporelle.`,
  most_mentionned_per_month: "Meilleurs compagnons",
  most_mentionned_per_month_p1: `Twitter Archive Explorer vous permet de voir rapidement, par mois ou sur
  la totalité de votre archive, avec qui vous avez le plus interagi sur Twitter.
  Qui étaient vos meilleurs amis d'il y a 5 ans ?`,
  background_tasks: "Tâches en arrière-plan",
  background_tasks_p1: `Avec un nombre considérable de tweets, favoris ou mutes, leur suppression peut être longue.
  Sans installer aucun programme, ou laisser un ordinateur allumé, supprimez avec des tâches lancées en
  arrière-plan. Vous pouvez les annuler à tout moment.`,
  tweets_of_the_day: "Tweets du jour",
  tweets_of_the_day_p1: `Juste pour le fun, ou par nostalgie, trouvez vos tweets postés le même jour,
  les années précédentes. Vous pourrez être surpris !`,
  twitter_at_history: "Historique de vos @ Twitter ",
  twitter_at_history_p1: `En tant qu'utilisateur chevronné de Twitter, vous êtes vous déjà demandé
  quand vous avez changé votre @ Twitter pour la dernière fois ?
  Archive Explorer vous laisse savoir chaque nom que vous avez emprunté, et quand vous l'avez quitté.`,
  open_and_private: "Ouvert et privé",
  open_and_private_p1: `Archive Explorer fait de votre confidentialité une priorité.
  Pas un seul de vos messages privés est stocké dans notre base de données.
  Vos données d'archive Twitter restent dans votre navigateur.
  Vous avez un doute ? Le code est`,
  open_source: "open-source",
  ae_made_by: "Archive Explorer est un outil open-source réalisé par",
  server: 'Serveur',
  client: 'Client',
  archive_reader: 'Lecteur d\'archive',
  help_with_donate: "Ce site vous a été utile ? Vous pouvez l'aider avec votre ❤️ en donnant un",
  little: "petit quelque chose",
  find_your_favorites_favorites: "Retrouvez vos favoris favoris",
  find_favorites_p1: `Compliqué de retrouver quels tweets postés il y a des années vous avez mis en favori ? Certainement pas avec Archive Explorer !`,
  find_favorites_p2: `Avec un simple explorateur par mois, naviguer à travers tous vos favoris n'a jamais été aussi simple !
  Vous pouvez même utiliser des sélecteurs temporels dans le champ de recherche intégré afin d'affiner vos requêtes.`,
  intelligent_targeting: "Ciblage intelligent",
  intelligent_targeting_p1: "Vous avez des tweets en tête, mais impossible de les dénicher en explorant votre archive ?",
  intelligent_targeting_p2: `Si la recherche textuelle ne suffit pas, Archive Explorer vous propose un module de recherche avancé complet et puissant,
  vous permettant d'appliquer des filtres complexes sur vos tweets dans une interface simple d'utilisation.
  Intervalles de dates, nombre de retweets, langue, type de tweet... Demandez, vous trouverez.`,
  /** END PRESENTATION PAGE */

  /** BEGIN ARCHIVE CMPT */
  reading_dms: "Lecture des messages privés",
  reading_fav_moments_other: "Lecture des blocks, moments et autres informations",
  indexing_tweets: "Indexation des tweets",
  unzipping: "Dézippage",
  reading_tweets: "Lecture des tweets",
  reading_user_infos: "Lecture des informations utilisateur",
  gathering_user_data: "Récupération d'informations utilisateur",
  lightening_archive: "L'archive est un peu lourde, allègement en cours...",
  load_an_archive: "Lire votre archive",
  load_another_archive: "Charger une autre archive",
  click_here_to_load: `Cliquez ici pour charger une archive`,
  save_or_drag_drop: `Vous pouvez également glisser un fichier ZIP ici, ou choisir parmi une sauvegarde disponible.`,
  archive_bad_format: "L'archive ne peut pas être chargée. Chargez-en une nouvelle dans le format requis.",
  how_to_load_p1: `Chargez votre archive Twitter pour débuter l'exploration de vos données.
  Vous ne savez pas comment l'obtenir ? Vous pouvez simplement`,
  follow_the_guide: `suivre le guide`,
  on_date: "le",
  by_date: "par",
  account: "Compte",
  created_at: "créé le",
  twitter_account: "Compte Twitter",
  file: "Fichier",
  dont_own_archive: "Vous n'êtes pas le créateur de cette archive, vous ne pourrez pas en supprimer les tweets.",
  cant_show_dm_images: "Les images des tweets ou DMs de cette archive peuvent ne pas s'afficher.",
  credentials_expired_cant_deleted: "Les clés d'accès Twitter ont expiré, vous ne pourrez pas supprimer de tweets.",
  logout_and_in_in: "Déconnectez-vous puis connectez-vous à nouveau",
  load: 'Charger',
  another_f: 'une autre',
  quick_delete: "Suppression rapide",
  drop_archive_here: "Déposez votre archive ici",
  loading: "Chargement",
  please_wait: "Veuillez patienter",
  is_loaded: "est chargée",
  cant_save_archive_on_this_device: "La sauvegarde d'archives n'est pas disponible sur cet appareil.",
  last_tweet_on: "Dernier tweet le",
  dms: "DMs",
  saved_on: "Sauvegarde du",
  available_saved_archives: "Archives sauvegardées",
  no_archive_saved: "Aucune sauvegarde disponible",
  save_current_archive: "Sauvegarder l'archive chargée",
  delete_all_archives: "Supprimer toutes les sauvegardes",
  save_current_archive_explaination: `L'archive actuellement chargée sera sauvegardée dans votre navigateur.
  Vous la retrouverez dans la liste des archives sauvegardées, si vous vous reconnectez avec le même compte Twitter,
  sur cet appareil.`,
  save: "Sauvegarder",
  saving: "Sauvegarde",
  it_may_take_a_while: "Ceci peut prendre du temps",
  unable_to_save_archive: "Impossible de sauvegarder l'archive actuelle.",
  remove_one_archive: "Supprimer cette archive",
  remove_all_archives: "Supprimer toutes les archives",
  remove_archives_explaination: `Les archives supprimées ne peuvent être récupérées.`,
  remove_all_archives_explaination: `Par défaut, seules les sauvegardes associées à votre compte Twitter sont effacées.
  Si vous souhaitez effacer l'intégralité des sauvegardes présentes sur l'appareil, cochez la case ci-dessous.`,
  remove: "Supprimer",
  removing: "Suppression",
  remove_all_checkbox: "Supprimer toutes les sauvegardes de l'appareil",
  reading_saved_archive: "Lecture de l'archive sauvegardée",
  quota_warning: "Votre quota de stockage est presque atteint. La sauvegarde de nouvelles archives peut échouer. (Utilisation ",
  quota_used: "",
  megabytes_used: "Mo utilisé",
  used_with_s: "s",
  user_archive_save_mismatch: `L'utilisateur connecté n'est pas le propriétaire de l'archive actuellement chargée.
  Si vous la rechargez via sa sauvegarde, les images des tweets et des messages privés peuvent ne pas s'afficher correctement.`,
  omg_what_happend: "Oups ! Que s'est t-il passé ?",
  why_my_archive_didnt_load: "Pourquoi mon archive ne s'est pas chargée ?",
  an_error_occured_with_archive_loading: "Une erreur est survenue lors de la lecture de votre archive.",
  unknown_read_archive_error_without_msg: "Erreur inconnue. Impossible d'obtenir plus d'informations.",
  unknown_read_archive_error: "Une erreur inconnue est survenue : ",
  the_file: 'Le fichier',
  cannot_be_found_in_archive: "n'a pas pu être trouvé dans l'archive",
  file_might_be_too_big: "L'archive ou un fichier à l'intérieur est peut être trop gros.",
  file_with_an_incorrect_format: "Le format d'un fichier à l'intérieur de l'archive est incorrect ou non supporté.",
  incorrect_file_in_archive: "Un fichier de l'archive présente une syntaxe incorrecte.",
  file_is_not_a_zip_archive: `Le fichier spécifié n'est pas une archive ZIP.
  Vous devez charger le fichier mis à disposition par Twitter, non décompressé.`,
  files_in_this_archive: "Fichiers dans cette archive",
  tweet_file_is_incorrect: "Le fichier contenant les tweets est mal formaté.",
  profile_informations_file_is_incorrect: "Le fichier contenant les informations sur votre profil est mal formaté.",
  dm_file_is_incorrect: "Le fichier contenant les messages privés est mal formaté.",
  files_in_archive_explaination: `Pour que l'archive fonctionne, les fichiers tweet.js, profile.js et account.js doivent se
  trouver à la racine de l'archive.`,
  download_error_dump: "Télécharger le rapport d'erreur",
  you_can_put_the_content_of_log_file_to: "Vous pouvez copier le contenu du rapport sur ",
  and_send_this_error_dump_to: "et l'envoyer à",
  you_can_also: "Vous pouvez également ",
  open_an_issue_and_attach_file: "ouvrir une issue sur GitHub et y attacher le rapport",
  create_classic_archive: "Créer une archive hors-ligne",
  click_here_to_download_it: "Cliquez ici pour la télécharger",
  your_archive_is_ready: "Votre archive est prête",
  archive_generation_wait: "Veuillez patienter pendant la création de l'archive. Ceci peut prendre du temps.",
  create_archive_title: "Créez une archive “classique” pour lire vos tweets hors-ligne avec une application web intégrée à celle-ci.",
  create_archive_p1: "Ce type d'archive ne contient rien d'autre excepté les tweets.",
  create_archive_p2: `Ne substituez pas votre archive actuelle par ce type d'archive,
  ou vous perdrez les messages privés, médias, ou encore les informations utilisateur comme
  l'historique des @, l'adresse e-mail et plus.`,
  create_archive_p3: `Attention : Dans l'archive générée, les retweets seront malformés avec des mauvais @, ou photos de profil manquantes. Ce comportement est normal.`,
  create: "Créer",
  you_dont_have_any_tweet: "Vous n'avez aucun tweet dans votre archive",
  /** END ARCHIVE CMPT */

  /** BEGIN DMS CMPT */
  direct_messages: "Messages privés",
  group_conversations: "Conversations de groupe",
  archive_no_dms: "L'archive chargée ne supporte pas les messages privés.",
  downloading_users: "Chargement des données utilisateur",
  reading_dm_img: "Préparation des médias de vos messages privés",
  no_conversations: "Aucune conversation",
  need_one_conversation: "Vous devez avoir au moins une conversation pour explorer vos messages.",
  /** END DMS CMPT */

  /** BEGIN DM CMPT */
  full_image: "Image entière",
  changes_conversation_name_to: "a changé le nom de la conversation en",
  invited: "a invité",
  to_this_conversation: "dans ce groupe",
  left: "est parti",
  left_plural: "sont partis",
  added_you: "vous a ajouté",
  last_message_on: "Dernier message le",
  /** END DM CMPT */

  /** BEGIN DMContainer CMPT */
  jump_to_message: "Aller au message sélectionné",
  /** END DMContainer CMPT */

  /** BEGIN DMConversation CMPT */
  user_not_resolved_end: "ne peut pas être résolu, il sera ignoré",
  back_to_conversations: "Liste des conversations",
  full_conversation: "Conversation entière",
  messages_of_the_day: "Messages du jour",
  find_dms: "Trouver des messages",
  select_a_month: "Sélectionnez un mois",
  choose_month_messages: "Choisissez un mois pour voir vos messages privés.",
  no_message: "Aucun message",
  any_message_here: "Vous n'avez aucun message ici",
  all_messages: "Tous les messages",
  conversation_with: "Conversation avec",
  /** END DMConversation CMPT */

  /** BEGIN Explore CMPT */
  tweets_menu: "Tweets",
  explore: "Explorer",
  full_archive: "Archive entière",
  find_tweets: "Trouver des tweets",
  search_now: "Rechercher",
  choose_month_tweets: "Choisissez un mois pour voir vos tweets",
  search_results: "Résultats de la recherche",
  year: "Année",
  moments_of_decade: "Moments de la décennie",
  search_with_case_sensitive: "Recherche sensible à la casse",
  multiline_regex_dot: "[Regex] Point (.) valide le retour à la ligne",
  search_match_tweet_name: "Recherche dans le TN ou @ Twitter",
  search_options: "Options de recherche",
  tweet_count_modal_title: "Évolution du nombre de tweets",
  posted_tweets_per: "Tweets publiés par",
  day_of: "jour de",
  month: "mois",
  tweet_count_chart: "Nombre de tweets publiés",
  most_mentionned: "Personnes les plus mentionnées",
  in_all_archive: "dans l'archive",
  during: "en",
  no_mentionned_users: "Aucun utilisateur mentionné",
  other_mentionned_users: "Autres utilisateurs",
  load_more: "Charger plus",
  mentionned_directly: "Mentionné directement",
  inside_a_conversation: "mentionné dans une conversation",
  times: "fois",
  most_mentionned_modal_title: "Mentions",
  statistics: "Statistiques",
  clear_input: "Vider l'entrée",
  clear_history: "Effacer l'historique de recherche",
  search_history: "Historique de recherche",
  history_cleared: "Historique de recherche effacé.",
  select_tweets: "Sélection de tweets",
  select_tweets_until: "Jusqu'à ce tweet",
  select_tweets_below: "Après ce tweet",
  select_tweets_until_first: "Jusqu'au premier tweet sélectionné",
  select_tweets_below_last: "Jusqu'au dernier tweet sélectionné",
  invalid_query: "Le critère suivant le mot-clé \"{}\" dans la recherche est invalide.",
  search_cannot_be_made: "La recherche n'a pas pu s'effectuer. Quelque chose s'est mal passé.",
  advanced_search: "Recherche avancée",
  advanced_search_explaination: `Cet outil vous permet de composer une recherche en fonction de critères de votre choix. Enfin,
  une fois vos critères sélectionnés, vous pouvez saisir du texte libre pour compléter votre demande.`,
  free_text: "Texte libre",
  since_date: "Depuis le",
  until_date: "Jusqu'au",
  day: "jour",
  retweet_count: "Nombre de retweets",
  favorite_count: "Nombre de favoris",
  popularity: "Popularité",
  make_search: "Faire la recherche",
  operator: "Opérateur",
  value: "Valeur",
  adv_search_result: "Recherche construite",
  empty_adv_search: "Vous n'avez saisi aucun critère",
  add_a_criteria: "Ajouter un critère",
  higher_than: "Plus de (>)",
  starting_at: "Au moins (≥)",
  lower_than: "Moins de (<)",
  equal_to: "Égal à (=)",
  until_operator: "Jusqu'à (≤)",
  invalid_value: "Valeur erronée",
  confirm: "Confirmer",
  sort_by_random: "Tri aléatoire",
  search_in_current_month: "Chercher dans le mois ou la vue courante",
  media_count: 'Nombre de médias',
  mentions_count: 'Nombre de mentions',
  undefined_text: "[Le texte du tweet est inaccessible]",
  has_search_choices: "Contient un(e)...",
  is_search_choices: "Est un(e)...",
  image: "Image",
  video: "Vidéo",
  gif: "GIF",
  link: "Lien",
  retweet: "Retweet",
  quote: "Citation",
  tweet_identifier: "Identifiant de tweet",
  user_identifier: "Identifiant utilisateur",
  reply: "Réponse",
  not_a_reply: "Pas une réponse",
  hashtag: "Hashtag",
  src_contains: "La source contient",
  url_contains: "Avec un lien contenant",
  lang: "Langue",
  see_full_tweet: 'Voir détail',
  /** END Explore CMPT */

  /** BEGIN Login CMPT */
  tiny_access_text: `Nous avons besoin d'un petit accès à votre compte Twitter pour
  nous autoriser à lancer des suppressions de tweets, accéder à votre contenu ainsi que les détails de votre profil.
  Vous pouvez supprimer l'accès quand vous le souhaitez.`,
  using: "En utilisant",
  sign_in_with_twitter: "Se connecter avec Twitter",
  you_allow: "vous autorisez",
  login_details_store_end: `à stocker vos clés d'accès Twitter
  ainsi que vos informations de connexion, comme la date actuelle ou votre adresse IP`,
  error_occured: "Une erreur est survenue",
  /** END Login CMPT */

  /** BEGIN QuickDelete CMPT */
  start_task: "Lancer la tâche",
  select_months: "Sélection de mois",
  delete_selected_tweet: "Supprimer le{} tweets sélectionné",
  will_be: "vont être",
  permanently: "définitivement",
  deleted_from_twitter: "supprimés de Twitter",
  do_you_really_want_to_do: "Voulez-vous vraiment faire cela",
  previous: 'Précédent',
  next: 'Suivant',
  erase_tweets: 'Supprimer les tweets',
  /** END QuickDelete CMPT */

  /** BEGIN Settings-ExtendedActionsMenu CMPT */
  unable_load_sessions: "Impossible de charger les sessions. Réessayez ultérieurement",
  unable_delete_account: "Impossible de supprimer votre compte. Vérifiez votre connexion à Internet.",
  unable_revoke_token: "Impossible de révoquer cette clé. Réessayez ultérieurement.",
  current: 'actuelle',
  session_created_on: "Session créée le",
  with_ip_address: "avec l'adresse IP",
  last_use_on: "Dernière utilisation le",
  revoke: "Révoquer",
  no_open_session: "Vous n'avez aucune session ouverte",
  delete_your_account: "Supprimer votre compte",
  delete_account_1_text: `Vous allez être déconnecté de chaque appareil que vous avez utilisé
  pour explorer des archives. Voulez-vous vraiment continuer ?`,
  delete_account_2_text: "Vous pourrez re-créer un compte plus tard en utilisant le bouton",
  button_delete_account: "", // french: no, in d_a_2
  confirm_action: "Veuillez confirmer votre action",
  will_cancel_running_tasks: "Si vous avez actuellement des tâches en cours, elles seront annulées",
  delete_account: "Supprimer le compte",
  advanced_settings: "Paramètres avancés",
  open_sessions: "Sessions ouvertes",
  delete_my_account: "Supprimer le compte",

  download_from_twitter_checkbox: "Télécharger les tweets depuis Twitter (donne des informations plus précises)",
  download_rt_from_twitter_checkbox: "Télécharger les retweets automatiquement (les archives RGPD contiennent des données de RT partielles)",
  show_profile_pictures: "Afficher les images de profil",
  account_created_on: "Compte créé le",
  thats_all_infos: "Ce sont toutes les informations que nous avons sur vous. Archive Explorer ne stocke aucune autre donnée.",
  twitter_credentials_expired: "Les clés d'accès à Twitter ont expiré. Veuillez vous reconnecter",
  automatic_dark_mode: "Mode nuit automatique",
  enable_dark_mode: "Activer le mode nuit",
  really_want_to_logout: "Voulez-vous vous déconnecter ? Vous ne pourrez plus utiliser cette application tant que vous ne vous serez pas reconnecté.",
  display: "Affichage",
  language: "Langue",
  use_local_medias: "Charger les images depuis l'archive si nécessaire",
  use_local_videos: "Charger les vidéos depuis l'archive si nécessaire (attention, cette option peut induire une forte consommation de RAM)",
  show_tweets_as_list: "Voir les tweets sous forme de liste compacte",
  /** END Settings-ExtendedActionsMenu CMPT */

  /** BEGIN Task CMPT */
  tweet_deletion: "Suppression de tweets",
  block_deletion: "Suppression de blocks",
  mute_deletion: "Suppression de mutes",
  unknown_deletion: "Tâche de type inconnue",
  completed: "terminé",
  deleted: "supprimé",
  failed: "échoué",
  remaining: "restant",
  subscribe: "S'abonner",
  unsubscribe: "Se désabonner",
  errors_encountered: "Erreurs survenues",
  twitter_error__inexistant_user: "L'utilisateur détenant le tweet n'existe pas.",
  twitter_error__suspended_user: "L'utilisateur détenant le tweet a été suspendu.",
  twitter_error__user_disconnected: "Vos clés d'accès à Twitter sont invalides ou ont été révoquées.",
  twitter_error__inexistant_tweet: "Le tweet n'existe pas.",
  twitter_error__protected_user: "L'utilisateur auquel appartient le tweet est protégé.",
  twitter_error__unknown_error: "Erreur inconnue (code {}).",
  /** END Task CMPT */

  /** BEGIN TaskModal CMPT */
  any_subscription: "Vous n'avez aucun abonnement",
  unable_fetch_tasks: "Impossible de charger les tâches en cours. Vérifiez votre connexion à Internet.",
  subscribed: "Abonnements",
  other_running_tasks: "Autres tâches en cours",
  completed_upper: "Terminées",
  empty_task_list: "Aucune tâche",
  can_be_started_with_explorer: "Les tâches de suppression peuvent être lancées depuis l'explorateur de tweets ou depuis l'onglet Plus.",
  tasks: "Tâches",
  /** END TaskModal CMPT */

  /** BEGIN More CMPT */
  extended_archive_data: "Données d'archives supplémentaires",
  help: "Aide",
  download_twitter_archive: "Télécharger votre archive Twitter",
  learn_how_to_download: `Apprenez à télécharger votre archive Twitter avec ce simple tutoriel. Vous en aurez pour quelques minutes.`,
  search: "Recherche",
  about_direct_messages: "Concernant les messages privés",
  search_p1: "Les recherches pour les tweets et messages privés s'effectent sans sensibilité à la casse, et celles-ci supportent les expressions régulières.",
  search_p2: `Si vous effectuez un clic droit (appui long sur mobile) sur le bouton rechercher,
  vous aurez accès à des options avancées de recherche, comme la sensibilité à la casse ou des drapeaux pour les expressions régulières.`,
  tweets_p1: "Cherchez les tweets que vous souhaitez directement via votre archive. La recherche se fait instantanément, dans tous vos tweets.",
  direct_messages_p1: `Quand une conversation est sélectionnée, vous pouvez trouver des messages via leur contenu. Lorsque vous avez
  trouvé celui que vous souhaitez, un simple clic sur celui-ci vous montre les messages suivant et précédant dans la conversation.`,
  keywords_upper: "Mots clés",
  keywords_p1: "Chaque recherche dans les tweets / messages privés peut être améliorée en ajoutant des",
  keywords_p2: `La recherche de tweets dans l'onglet Explorer peut être agrémentée de plus de sélecteurs encore. Sur ordinateur, un outil
  vous permettant de composer une recherche avancée visuellement est disponible pour vous aider à les découvrir.`,
  keywords: "mots clés",
  delete_tweets_more: "Supprimer des tweets, des mutes et plus encore",
  delete_tweets_more_p1: "Archive Explorer vous aide à supprimer des tweets, ou d'autres informations liées à votre compte.",
  delete_tweets_more_p2: "Veuillez noter que",
  delete_tweets_more_p3: "chaque suppression est réalisée sur votre compte Twitter, et est irréversible.",
  delete_tweets_more_p4: "Vous ne serez pas capable de récupérer vos tweets, mutes ou autres informations supprimées",
  tweets_more_p1: `Pour les tweets, vous pouvez les sélectionner individuellement dans l'explorateur de tweets (onglet Explorer),
  ou choisir depuis plusieurs mois ou années dans la Suppression rapide, accessible dans l'onglet Archive.`,
  favs_mutes_blocks: "Mutes et blocks",
  favs_mutes_blocks_p1: `Les mutes et blocks peuvent être supprimés dans leur onglet respectif, en haut de cette page. Ce type de suppression est "tout ou rien", vous ne pouvez pas
  sélectionner individuellement quel favori ou block vous allez supprimer.`,
  limitations: "Limitations",
  retweet_data: "Données des retweets",
  retweet_data_p1: `Lorsque vous naviguez dans vos tweets, vous pouvez apercevoir que les retweets contiennent des données étranges :
  L'image de profil peut être la vôtre, ou le TN n'est pas bon.
  En réalité, les archives Twitter contiennent des données partielles. Les retweets sont stockés en votre nom,
  et le tweet retweeté original n'est pas présent.`,
  retweet_data_p2: `Archive Explorer essaie d'étendre au maximum les données fournies, pour rendre votre expérience la plus agréable possible,
  mais il est impossible de faire des miracles.`,
  truncated_tweets: "Tweets tronqués",
  truncated_tweets_p1: `Les tweets contenant plus de 140 caractères peuvent être tronqués. Ceci est peut être un bug
  (voir`,
  this_tweet: "ce tweet",
  truncated_tweets_p2: `ou non, Twitter n'a fourni aucun élément de réponse.`,
  truncated_tweets_p3: `Vous pouvez activer une option permettant de télécharger les tweets depuis Twitter plutôt
  que depuis l'archive dans les Paramètres, afin d'avoir le texte complet.
  Cependant, l'affichage des tweets sera considérablement plus lent.`,
  tasks_limit: "Limites de tâches",
  tasks_limit_p1: `Les tâches de suppression sont très demandeuses de ressources pour le serveur et sont,
  par conséquent, limitées pour chaque utilisateur. Vous êtes capable de démarrer`,
  tasks_limit_p2: `3 tâches`,
  tasks_limit_p3: `en simultané.`,
  tasks_limit_p4: `Si vous souhaitez lancer une autre tâche, merci d'attendre la fin de vos suppressions ou en annulez en une.`,
  classic_not_supported: `L'archive classique n'est pas supportée.
  Vous devez avoir une archive RGPD afin d'accéder aux options disponibles ici.
  Apprenez à télécharger une archive RGPD avec l'aide ci-dessous.`,
  archive_not_loaded_p1: `Ici, vous avez accès à de nombreuses options supplémentaires, comme la suppression
  des blocks, des favoris et encore plus.
  Pour commencer, apprenez à télécharger une archive Twitter avec l'aide ci-dessous.`,
  archive_saving: "Sauvegarde d'archives",
  archive_saving_p1: `Pour éviter que vous ayez à recharger votre archive depuis un fichier à chaque fois
  que vous vous rendez sur Archive Explorer, nous vous proposons de l'enregistrer dans votre navigateur. Cette
  sauvegarde sera uniquement visible par l'utilisateur l'ayant créée.`,
  archive_saving_p2: `Toute sauvegarde est locale à l'appareil, elle n'est pas envoyée sur les serveurs d'Archive Explorer.`,
  more: "Plus",
  delete_all_your: "Supprimer tous vos",
  permanently_remove_your: "Cette action va supprimer définitivement tous vos",
  from_twitter_account: "de votre compte Twitter",
  are_you_sure_you_want: "Êtes-vous sûr•e que vous souhaitez faire ceci",
  you_have: "Vous avez",
  blocks: "Blocks",
  you_have_blocked: "Vous avez bloqué",
  users: "utilisateurs",
  delete_all_blocked: "Supprimer tous vos comptes bloqués",
  mutes: "Mutes",
  you_have_muted: "Vous avez mis en sourdine",
  delete_all_muted: "Supprimer tous les comptes mis en sourdine (mutes)",
  screen_name_history: "Historique des noms",
  twitter_at: "@ Twitter",
  until: "Jusqu'à",
  keyword: "Mot clé",
  content: "Contenu",
  description: "Description",
  example: "Exemple",
  limit_search_to_month: "Limite la recherche au mois sélectionné.",
  the_search: "La recherche",
  must: "doit",
  begin_by: "commencer par",
  find_tweets_since: "Cherche les tweets/messages postés après une date.",
  find_tweets_until: "Cherche les tweets/messages postés avant une date.",
  find_tweets_from: "Cherche les tweets/messages postés par un utilisateur.",
  blocked_modal: "comptes bloqués",
  muted_modal: "comptes mis en sourdine",
  ads_and_user_data: "Pubs & données utilisateur",
  email_address_history: "Historique des adresses e-mails",
  email_address: "Adresses e-mail",
  age_by_twitter: "Âge renseigné sur Twitter",
  you_are: "Vous avez",
  years_old: "ans",
  you_are_born_on: "Vous êtes né•e le",
  for_twitter: "Pour Twitter",
  between: "entre",
  language_spoken: "Langues parlées",
  on_twitter_you_usually_spoke: "Sur Twitter, vous parlez habituellement",
  gender: "Genre",
  to_twitter_you_are_a: "Selon Twitter, vous êtes",
  male: "un garçon",
  female: "une fille",
  undefined_gender: "d'un genre indéterminé",
  shows_and_interests: "Entitées et séries d'intérêt",
  things_that_interest_you: "Centres d'intérêt",
  names_interests_title: "L'ensemble des choses, d'après Twitter, susceptibles de vous intéresser. Celles-ci sont déterminées d'après vos interactions.",
  shows_that_interest_you: "Séries/Films vous intéressant",
  shows_interests_title: "L'ensemble des films, séries et programmes audio-visuels susceptibles de vous intéresser.",
  most_seen_advertisers: "Annonceurs les plus vus",
  impressions_per_date: "Impressions par date",
  impression_explaination: `Une impression est enregistrée lorsque vous "voyez" une publicité sur Twitter. Celle-ci peut être
  un tweet sponsorisé, une publicité pré-vidéo, une vidéo publicitaire, un sondage sponsorisé ou d'autres.
  Plus le nombre d'impressions est élevé, plus vous avez été soumis à de la publicité ce jour-là.
  Dans une archive, l'historique des impressions s'étend sur les 90 jours précédant la date de la création de celle-ci.`,
  followers: "Followers",
  followings: "Followings",
  untranslated: "non traduit",
  explaination_difference_follows: `Le nombre affiché peut ne pas refléter le chiffre disponible Twitter en raison de la
  présence des comptes suspendus et désactivés dans cette liste.`,
  legal_mentions_and_limitations: "Mentions légales et limitations",
  legal_mentions: "Mentions légales",
  about_archive_explorer: "À propos de Twitter Archive Explorer",
  about_archive_explorer_p1: `Twitter Archive Explorer est une suite logiciel créée par Alkihis.
  Celle-ci est utilisée conformément à sa licence CC-BY-NC-SA-4.0 et fournit une utilisation à titre gratuit sans contrepartie.
  L'ensemble de la suite, client, serveur et lecteur d'archive sont mis à disposition sur `,
  about_archive_explorer_p2: `En cas de réutilisation d'une partie de cette suite par d'autres personnes, elle doit respecter
  les conditions des licences affectées à chaque outil.`,
  about_archive_explorer_p3: `Archive Explorer n'appartient pas à Twitter et n'y est affilié d'aucune manière.
  Twitter, Tweet, Retweet et le logo Twitter sont des marques déposées de Twitter, Inc. ou ses affiliés.`,
  tweet_deletion_p1: `Lorsque vous supprimez des tweets, mutes, ou d'autres données en utilisant Twitter Archive Explorer,
  ces informations sont supposément effacées de manière permanente. Archive Explorer ne fournit aucune garantie, tant à l'effectivité
  de la suppression de ces données que la précision de cette suppression. Si d'autres données de votre compte sont supprimées par
  erreur, Archive Explorer ne fournit aucun moyen de recours pour récupérer ces données.`,
  personal_data: "Données personnelles",
  personal_data_p1: `Twitter Archive Explorer respecte votre vie privée et stocke le minimum nécessaire au fonctionnement
  du service. Sont stockées à propos de vous la date de votre dernière connexion, une courte description de vos informations Twitter
  (nom d'utilisateur, photo de profil, identifiant et clés d'accès à votre compte) ainsi que la date et l'emplacement de création de votre compte.`,
  used_software: "Logiciels et composants utilisés",
  used_software_p1: `La suite Archive Explorer utilise principalement les outils suivants :`,
  web_service: "Service web",
  favorites: "Favoris",
  favorited_tweet: "Tweet mis en favori",
  explore_favorites: "Explorer les favoris",
  find_favorites: "Trouver des favoris",
  archive_no_favorites: "L'archive chargée ne contient pas les tweets mis en favori.",
  contains_any_favorites: "Cet élément ne contient aucun tweet mis en favori",
  choose_month_favorites: `Choisissez un mois pour afficher les tweets mis en favori.`,
  choose_month_favorites_p2: `Les sélecteurs de date se réfèrent à la date des tweets, pas à la date de mise en favori`,
  favorited: "mis en favori",
  search_inside_favorites: "Recherche parmi les favoris",
  shown: "montré",
  shown_s: "montrés",
  over: "sur",
  favorited_tweets: "tweet{} mis en favori",
  favorites_of_the_day: "Favoris du jour",
  older_favorited_tweets_short: "Favoris plus anciens",
  older_favorited_tweets: "Favoris antérieurs à novembre 2010",
  favorites_p1: `En raison d'une limite interne à l'archive, les favoris sont triés sont triés en fonction de `,
  favorites_p2: "la date de publication des tweets mis en favoris, et non pas de la date de mise en favori",
  favorites_p3: `Dans l'explorateur de favoris, les tweets postés avant novembre 2010 ne peuvent être triés par date.
  Si il en existe, une catégorie "Favoris plus anciens" est créée en conséquence.
  De plus, si le tweet mis en favori a été supprimé, aucune autre information que le texte du tweet ne pourra être affichée dans l'explorateur.`,
  /** END More CMPT */

  /** BEGIN PlacingComponents CMPT */
  login_again: "Se reconnecter",
  /** END PlacingComponents CMPT */

  /** BEGIN Tasks file */
  task_cancelled: "La tâche a été annulée.",
  tasks_stopped: "Toutes les tâches ont été arrêtées.",
  task_started: "La tâche a été lancée.",
  task_rate_limit: "Vous avez atteint le nombre maximum de tâches en cours. Attendez qu'une tâche se termine avant d'en lancer une nouvelle.",
  task: "La tâche",
  has_ended: "s'est terminée",
  connection_lost_subscribe: "Pas de connexion au serveur. Vous devrez peut-être vous désabonner puis vous réabonner à cette tâche.",
  connection_lost: "Connexion avec le serveur perdue",
  connection_reopened: "Reconnecté au serveur",
  /** END Tasks file */

  past_s: "s",

  /** BEGIN CloudedArchives file */
  clouded_archive: "Archives dans le nuage",
  cloud_download_failure: "Impossible de télécharger l'archive.",
  already_sent: "Cette archive est déjà présente dans le nuage.",
  archive_successfully_uploaded: "Archive téléversée avec succès.",
  cloud_upload_failure: "Impossible de téléverser l'archive.",
  downloading_clouded_archive: "Téléchargement de l'archive",
  upload_archive: "Envoi de l'archive",
  compressing_archive: "Compression...",
  uploading_archive: "Téléversement de l'archive vers Archive Explorer",
  finishing_clouding_archive: "Plus que quelques instants, nous terminons l'envoi...",
  already_saved: "Cette archive est déjà présente sur votre appareil.",
  clouded_archive_removed: "Archive supprimée du nuage.",
  unable_to_delete_cloud_archive: "Impossible de supprimer l'archive distante.",
  list_of_clouded_archives: "Archives dans le nuage",
  here_are_the_saved_archives_on_your_cloud_account: "Vous pouvez accéder ici à une liste des archives sauvegardées à distance dans votre compte Archive Explorer.",
  click_on_a_clouded_save_to_download_it: "Cliquez sur une des archives disponibles pour la télécharger localement.",
  no_clouded_archive: "Aucune archive sauvegardée à distance",
  cloud_saved_at: "Sauvegardée le {}",
  cloud_a_new_archive: "Téléverser une archive",
  select_archive_from_local_saved_archives: "Sélectionnez une archive sauvegardée localement pour l'envoyer dans le nuage.",
  invalid_saved_archive_type: "Cette archive est dans un format trop ancien. Veuillez recharger le ZIP original, puis ré-enregistrer l'archive.",
  /** END CloudedArchives file */

} as { [msg: string]: string };
